<template>
    <div class="factory-statistics">
        <a-form layout="inline" :labelCol="{span: 4}" :wrapperCol="{span: 20}">
        <a-row :gutter="20">
            <a-col :span="8">
                <a-form-item label="厂商名称" >
                    <a-input placeholder="输入厂商名称" v-model="params.factoryName" allowClear @pressEnter="onSearch" @change="onSearch"/>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item label="查询时间" >
                       <a-range-picker  v-model="selectTime" @change="changeTime" @ok="changeTime" allowClear/>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-form-item>
                      <a-button type="primary" @click="onSearch">  查询 </a-button>
                </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-table  size="small" bordered  :columns="columns" :data-source="tableData" style="margin-top: 20px;" :loading="loading"   :pagination="pagination" >
            <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
               <a-row :gutter="20"   type="flex" justify="center" align="top">
                   <a-col :span="8">
                       <h3>采集类型</h3>
                       <p v-for="(item, index) in filterMap(record.typeCollect)" :key="index">
                          <a-tag>{{item.label}}：{{item.value}}</a-tag> 
                        </p>
                   </a-col>
                   <a-col :span="8">
                        <h3>采集时段（条数）</h3>
                       <p v-for="(item, index) in filterMap(record.collectTimeDistribute)" :key="index">
                           <a-tag>{{item.label}}：{{item.value}}</a-tag>
                        </p>
                   </a-col>
                    <a-col :span="8">
                        <h3>采集ip</h3>
                        <p> {{record.ipaddress}} </p>
                   </a-col>
               </a-row>
            </p>
        </a-table>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import { queryFactoryEquipmentApi } from '@/api/factory'

const columns = [
        {
            title: '厂商名称',
            dataIndex: 'manufacturerName',
            key: 'manufacturerName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '小区名称',
            dataIndex: 'centerName',
            key: 'centerName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '设备编号',
            dataIndex: 'equipmentCode',
            key: 'equipmentCode',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '设备状态',
            dataIndex: 'equipmentStatus',
            key: 'equipmentStatus',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '设备采集条数',
            dataIndex: 'collectCount',
            key: 'collectCount',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '无数据天数',
            dataIndex: 'hasNoDataDays',
            key: 'hasNoDataDays',
            ellipsis: true,
            align: 'center'
        },
]
export default {
    data() {
        return{
            params: { page: 1 },
            tableData: [],
            columns,
            loading: false,
            selectTime: [moment(), moment()],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
        }
    },
    mounted() {
        this.params.startTime = this.selectTime[0]
        this.params.endTime = this.selectTime[1]
        this.queryFactoryEquipment()
    },
    methods: {
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryFactoryEquipment()
        },
        filterMap(val) {
            let n = []
            _.forEach(val, function(value, key) {
                n.push({label: key, value: value})
            })
            return n
        },
        onSearch() {
            this.queryFactoryEquipment()
        },
        queryFactoryEquipment() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryFactoryEquipmentApi({...this.params}).then(res => {
                this.tableData = res.result.content
                this.pagination.total = res.result.totalElements
                this.loading = false
            })
        },
        changeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryFactoryEquipment()
        },
    }
}
</script>
<style lang="scss">
.factory-statistics{
  .ant-form-item{
      width: 100%;
    }
}
</style>